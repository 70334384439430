/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import type { Method, Url } from '@shared/types';
import { axiosInstance } from '@shared/utils';
import { PlatformType, Endpoints } from '@shared/constants';

export type AxiosRequestHeaders = Record<string, string | number | boolean>;

const customFetch = async <T, D>({
  method,
  url,
  request,
  config,
}: {
  method: Method;
  url: Url;
  request?: D;
  config: AxiosRequestConfig<D>;
}) => {
  if (method === 'get') {
    return axiosInstance.get<D, AxiosResponse<T>>(url, config);
  }

  return axiosInstance[method]<D, AxiosResponse<T>>(url, request, config);
};

export const useQueryFunction = <T = any, D = any>(
  method: Method,
  url: Url,
  request?: D,
  authToken?: string,
  requestConfig?: AxiosRequestConfig<D>,
  customHeaders?: Record<string, string | number | boolean | undefined>
) => {
  let headers: AxiosRequestHeaders = {
    ...customHeaders,
    'X-Process-ID': uuidv4(),
    'Content-type': 'application/json',
  };

  if ((<any>Object).values(Endpoints).includes(url)) {
    headers = {
      ...headers,
      'X-Platform-Type': PlatformType,
    };
  }

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  let config = {
    headers: authToken ? authHeaders : headers,
    ...requestConfig,
  };

  if (Endpoints.CONFIRMAUTH.includes(url)) {
    config = {
      withCredentials: true,
      ...config,
    };
  }

  const queryFn = async (data: any) => {
    return customFetch<T, D>({
      method,
      url,
      request: request || data,
      config: requestConfig ? requestConfig : config,
    });
  };

  return queryFn;
};
