import { useTranslation } from 'react-i18next';

import { StateBlock } from '@pulse-web-ui/state-block';
import { Modal } from '@pulse-web-ui/modal';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import { lazy, Suspense, useCallback } from 'react';

const AlertIcon = lazy(() => import('@pulse-web-ui/icons/lib/lazy/rgs-alert'));

export const LetsTakeABreakError = observer(() => {
  const {
    MainStore: {
      errorStore: {
        isLetsTakeABreakErrorVisible,
        setIsLetsTakeABreakErrorVisible,
      },
    },
  } = useStores();
  const { t } = useTranslation();

  const errorHandler = useCallback(() => {
    setIsLetsTakeABreakErrorVisible(false);
  }, [setIsLetsTakeABreakErrorVisible]);

  return (
    <Modal
      showDialog={isLetsTakeABreakErrorVisible}
      closeIcon={false}
      dangerouslyBypassScrollLock
      desktopWidth={536}
    >
      <StateBlock
        title={t('COMMON:headers.letsTakeABreak')}
        icon={
          <Suspense>
            <AlertIcon width={80} />
          </Suspense>
        }
        submitButtonText={t('COMMON:labels.tryAgain')}
        onSubmit={errorHandler}
      />
    </Modal>
  );
});
