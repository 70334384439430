import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from '@pulse-web-ui/alert';

import { Otp, SubmitButton, PromoAgreement } from './components';
import { USER_PERSONAL_DATA_AGREEMENTS } from './constants';
import {
  AdaptiveContainer,
  SiteAgreements,
  SubmitWrapper,
  Row,
  StyledPolicyLink,
} from './submit.styles';
import type { ProductPrice } from '@shared/types';
import { PromoCodeOptional } from './components/promo-code/promo-code-optional';
import { i18nDefaultValues } from './i18n';

// TODO: Экспортируется в проект.
export type SubmitProps = {
  onSubmit: () => void;
  b2pRoute?: string;
  useOtp?: boolean;
  buttonText?: string;
  hasPhone: boolean;
  handleOtp: (e: string, i: boolean) => void;
  handleAuth: (e: string) => void;
  otpIsLoading: boolean;
  authIsLoading: boolean;
  externalPhone?: string;
  setDisableForm: (e: boolean) => void;
  hasAuthorized: boolean;
  disabled: boolean;
  hideSubmit?: boolean;
  hasPricesCalcErr?: boolean;
  otpErrorCode?: boolean;
  setOtpErrorCode: (e: boolean) => void;
  authError?: boolean;
  captchaIsNotValidError?: boolean;
  setCaptchaIsNotValidError: (e: boolean) => void;
  currentStep: number;
  refetchAuthentication: (e: boolean) => void;
  pulseClient?: boolean;
  price: ProductPrice;
  onSetPrice: (e: Partial<ProductPrice>) => void;
  onClearPromoCode: () => void;
  isShowPromoCode: boolean;
  setIsCaptchaVisible: (e: boolean) => void;
  captchaToken: string | undefined;
};

const Submit: FC<SubmitProps> = ({
  onSubmit,
  useOtp,
  buttonText,
  hasPhone,
  handleOtp,
  otpIsLoading,
  handleAuth,
  authIsLoading,
  externalPhone,
  setDisableForm,
  hasAuthorized,
  disabled,
  hideSubmit,
  hasPricesCalcErr,
  currentStep,
  otpErrorCode,
  setOtpErrorCode,
  authError,
  captchaIsNotValidError,
  setCaptchaIsNotValidError,
  refetchAuthentication,
  pulseClient,
  onSetPrice,
  onClearPromoCode,
  price,
  isShowPromoCode,
  b2pRoute,
  setIsCaptchaVisible,
  captchaToken,
}) => {
  const [showOtp, setShowOtp] = useState(false);
  const [promoAgreementVal, setPromoAgreementVal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!useOtp) {
      setShowOtp(false);
    }
  }, [useOtp]);

  const handleShowOtp = () => {
    setIsCaptchaVisible(true);
  };

  useEffect(() => {
    if (captchaToken && useOtp) {
      setDisableForm(true);
      setShowOtp((prev) => !prev);
    }
  }, [captchaToken, useOtp]);

  const submitValue: Record<number, string> = {
    1: t('SMART:Submit.labels.toSelectOptions', {
      defaultValue: i18nDefaultValues.Submit.labels.toSelectOptions,
    }),
    2: t('SMART:Submit.labels.toRegister', {
      defaultValue: i18nDefaultValues.Submit.labels.toRegister,
    }),
  };

  const handle = () => {
    return useOtp && externalPhone ? handleShowOtp() : onSubmit();
  };

  if (hideSubmit) {
    return null;
  }

  const handleChangePromoAgreement = (checked: boolean) => {
    setPromoAgreementVal(checked);
  };

  const handleOtpFn = (e: string) => {
    handleOtp(e, promoAgreementVal);
  };

  const handleHideOtp = () => {
    setShowOtp(false);
    handleOtpFn('');
  };

  const handleClearPromoCode = () => {
    onClearPromoCode();
  };

  const handleSubmitPromoCode = (promoCode?: string) => {
    onSetPrice({ promoCode, isApplyedPromo: promoCode !== '' });
    handleClearPromoCode();
  };

  const handleSetPrice = (price: Partial<ProductPrice>) => {
    onSetPrice(price);
  };

  return (
    <SubmitWrapper isShowOtp={!!(showOtp && !hasAuthorized && externalPhone)}>
      {isShowPromoCode && (
        <PromoCodeOptional
          onChange={handleSetPrice}
          price={price}
          onSubmit={handleSubmitPromoCode}
          onClear={handleClearPromoCode}
        />
      )}
      {useOtp && !hasAuthorized && !pulseClient ? (
        <Row>
          <PromoAgreement
            value={Boolean(promoAgreementVal)}
            onChange={handleChangePromoAgreement}
            disabled={disabled}
          />
        </Row>
      ) : null}
      {hasPricesCalcErr ? (
        <Alert
          type="error"
          description={t('SMART:Submit.hints.overPriceError', {
            defaultValue: i18nDefaultValues.Submit.hints.overPriceError,
          })}
        />
      ) : null}
      {showOtp && !hasAuthorized && externalPhone ? (
        <Otp
          handleOtp={handleOtpFn}
          handleAuth={handleAuth}
          displayPhone={hasPhone}
          otpIsLoading={otpIsLoading}
          authIsLoading={authIsLoading}
          externalPhone={externalPhone}
          setDisableForm={setDisableForm}
          otpErrorCode={otpErrorCode}
          setOtpErrorCode={setOtpErrorCode}
          authError={authError}
          captchaIsNotValidError={captchaIsNotValidError}
          setCaptchaIsNotValidError={setCaptchaIsNotValidError}
          refetchAuthentication={refetchAuthentication}
          hideOtp={handleHideOtp}
        />
      ) : (
        <AdaptiveContainer>
          <SubmitButton
            onClick={handle}
            buttonText={submitValue[currentStep] || buttonText}
            currentStep={currentStep}
            b2pRoute={b2pRoute}
            disabled={
              authIsLoading ||
              otpIsLoading ||
              (hasPricesCalcErr && currentStep !== 1) ||
              disabled
            }
          />
          {useOtp && !hasAuthorized ? (
            <Trans
              components={{
                SiteAgreements: <SiteAgreements />,
                StyledPolicyLink: <StyledPolicyLink />,
              }}
              values={{ href: USER_PERSONAL_DATA_AGREEMENTS }}
            >
              {t('SMART:Submit.hints.siteAgreements', {
                defaultValue: i18nDefaultValues.Submit.hints.siteAgreements,
              })}
            </Trans>
          ) : null}
        </AdaptiveContainer>
      )}
    </SubmitWrapper>
  );
};

export default Submit;
